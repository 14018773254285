//@flow
// import {
//   findGetParameter
// } from 'lib/Helpers';
import * as React from 'react';
import JobList from './job-list';
import css from './shift.module.scss';
// import {
//   FontAwesomeIcon
// } from '@fortawesome/react-fontawesome';
import moment from 'moment'
type ShiftProps = {
  shift: Object
}

class Shift extends React.Component<ShiftProps, *> {

  render() {
    if (!this.props.shift) return null
    const start = moment(this.props.shift.start);

    return <div className={css.shift}>
      <div className={css.shiftHeader}>
        <div className={css.shiftheaderDate}>{start.format("dddd Do MMMM")}</div>
        <div className={css.shiftHeaderName}>{this.props.shift.gtDriver && this.props.shift.gtDriver.person.firstname}</div>
      </div>
      <JobList jobs={this.props.shift.gtJobs.edges} />
    </div>;
  }
}

export default Shift;