//@flow
import {
  getPrettyDurationFromSecs,
  getPrettyDistanceFromMetres
} from '../lib/Helpers';
import * as React from 'react';
import css from './job.module.scss';
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import moment from 'moment'

type JobProps = {
  job: Object
}

class Job extends React.Component<JobProps, *> {

  handleLocationClick(l: Object) {
    let s = "";
    console.log(l);
    if (l.latitude && l.longitude) {
      s = l.latitude + "," + l.longitude
    } else if (l.postcode) {
      s = l.postcode.replace(/ /g, '');
    }
    const url = `https://www.google.co.uk/maps?q=${s}`;
    if (window.confirm(`Open ${l.name} in Google Maps?`)) {
      window.open(url, '_blank')
    }
  }


  render() {
    if (!this.props.job) return null
    // const now = moment();
    const job = this.props.job;
    const at = moment(job.pickups.edges[0].node.at);
    // const hasPast = now > at;

    const from = job.pickups.edges[0].node.from;
    const to = job.pickups.edges[0].node.to;
    const time = job.pickups.edges[0].node.journey && job.pickups.edges[0].node.journey.time;
    const distance = job.pickups.edges[0].node.journey && job.pickups.edges[0].node.journey.distance;
    // const totalNumberOfPassengers = job.pickups.edges.reduce((acc, edge) => {
    //   return acc + edge.node.numberOfPassengers
    // }, 0)

    const arriveTime = moment(at);
    arriveTime.add(time, 's');

    const pickupNames = job.pickups.edges.map(edge => {
      const numberOfPassengers = edge.node.numberOfPassengers;
      return <div key={edge.node.id} className={css.pickupName}>
        <div className={css.pickupTag}>Pickup {numberOfPassengers} {numberOfPassengers !== 1 ? "people with" : "person"}</div>
        <div>{edge.node.pickupName ? edge.node.pickupName : (edge.node.booking.leadArtistName ? edge.node.booking.leadArtistName : edge.node.booking.title)}</div>
        <div className={css.pickupNotes}>{edge.node.notes}</div>
      </div>;
    });
// console.log(job.id)
    return <div className={css.jobWrapper}>
      <div className={css.jobDriver}>
        Job ID #{job.id.substr(-4)}<br />
      </div>

      <div className={css.jobDriver}>

        {job.shift.gtDriver?.person.name}
      </div>

      <div className={css.row}>
        <div className={css.jobTime}>
          {at.format("HH:mm")}
        </div>
        <div className={css.column}>
          <div className={css.jobDate}>
            &nbsp;{at.format("dddd")}
          </div>
          <div className={css.jobDate}>
            &nbsp;{at.format("Do MMMM")}
          </div>
        </div>
      </div>

      <div className={css.row2}>

        <div className={css.verticalArrow}>
          <div><FontAwesomeIcon icon="car" /></div>
          <div>•</div>
          <div>•</div>
          <div>•</div>
          <div><FontAwesomeIcon icon="map-marker-alt" /></div>
        </div>

        <div>
          <div className={css.jobFrom}>
            <div onClick={() => this.handleLocationClick(from)} className={css.locationRow}>
              <div>{from.name}</div>
              <div className={css.icon}>
                <FontAwesomeIcon icon="chevron-circle-right" />
              </div>
            </div>
            <div onClick={() => this.handleLocationClick(from)} className={css.address}>{from.address} {from.postcode}</div>
            {pickupNames}
          </div>


          <div className={css.jobTransit}>
            <div>
              {getPrettyDistanceFromMetres(distance)} / ~{getPrettyDurationFromSecs(time)}
            </div>
          </div>


          <div className={css.jobTo}>
            <div>
              <div onClick={() => this.handleLocationClick(to)} className={css.locationRow}>
                <div>{to.name}</div>
                <div className={css.icon}>
                  <FontAwesomeIcon icon="chevron-circle-right" />
                </div>
              </div>
              <div onClick={() => this.handleLocationClick(to)} className={css.address}>{to.address} {to.postcode}</div>
              <div className={css.arriveTime}>Arrive ~{arriveTime.format("HH:mm")}</div>
            </div>

          </div>
        </div>

      </div>


    </div>;
  }
}

export default Job;