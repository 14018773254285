//@flow
import * as React from "react";
// import env from "../app/relay/environment";
import moment from "moment";
import DriverHub from "./driverhub";
import Loading from "../lib/Loading";
import { useQuery, gql } from "@apollo/client";
const rootQuery = gql`
  query driverhubContainer_GetData_Query($afterDate: String, $brandId: ID!) {
    public {
      brand(id: $brandId) {
        id
        name
        gtShifts(afterDate: $afterDate) {
          edges {
            node {
              id
              start
              end
              gtDriver {
                id
                person {
                  name
                  phoneMobile
                  firstname
                }
              }
              gtVehicle {
                make
                model
                passengerCapacity
              }
              gtJobs {
                edges {
                  node {
                    id
                    notes
                    shift {
                      gtDriver {
                        person {
                          name
                        }
                      }
                    }
                    pickups {
                      edges {
                        node {
                          id
                          pickupName
                          from {
                            id
                            name
                            address
                            postcode
                            phone
                            latitude
                            longitude
                          }
                          to {
                            id
                            name
                            address
                            postcode
                            phone
                            latitude
                            longitude
                          }
                          at
                          numberOfPassengers
                          notes
                          journey {
                            distance
                            time
                          }
                          booking {
                            leadArtistName
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DriverHubContainer = (props: Object) => {
  const { loading, error, data } = useQuery(rootQuery, {
    variables: {
      afterDate: moment().startOf("d").subtract(14, "days").format("YYYY-MM-DD"),
      brandId: "1110000000000002",
    },
    pollInterval: 60000,
  });

  if (loading) return <Loading />;
  if (error) return null;
  return <DriverHub data={data} {...props} />;
};

export default DriverHubContainer;
