//@flow
// import {
//   getPrettyDate
// } from 'lib/Helpers';
import * as React from 'react';

import css from './shift-menu.module.scss';
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  Actions
} from '../data/actions';

type ShiftMenuProps = {
  shifts: Array<Object>
}
type ShiftMenuState = {}

class ShiftMenu extends React.Component<ShiftMenuProps, ShiftMenuState> {

  handleSetPage() {
    // console.log("set page", 1);
    Actions.setPage(1)
  }

  handleTouchShift(shift: Object) {
    // console.log("set shift", shift.gtDriver.person.name);
    Actions.setShift(shift);
  }

  render() {

    let day = null;

    const menu = this.props.shifts.map((edge, i) => {
      const start = moment(edge.node.start);


      let showDay = false;
      if (!day || !day.isSame(start, 'day')) {
        day = start;
        showDay = true;
      }

      if (day === null) day = start;

      const hasJobs = edge.node.gtJobs.edges.length > 0;
      const active = hasJobs ? null : css.inactive;

      return <div key={i}>
        {showDay ? <div className={[css.bigDay, css.shiftMenuItem].join(" ")}>{start.format("dddd Do MMMM")}<hr /></div> : null}

        <div className={[css.shiftMenuItem, active].join(" ")} key={i}
          onMouseUp={() => this.handleSetPage()}
          onMouseDown={() => { this.handleTouchShift(edge.node) }}
          onTouchStart={() => { this.handleTouchShift(edge.node) }}
        >

          <div className={css.shiftMenuLabel}>
            <div className={[css.shiftMenuItemStart, active].join(" ")}>{start.format("dddd Do MMMM")}</div>
            <div className={[css.shiftMenuItemName, active].join(" ")}>{edge.node.gtDriver?.person?.name || "NO DRIVER ASSIGNED"}</div>
          </div>
          <div className={css.shiftMenuIcon}>
            <FontAwesomeIcon icon="chevron-circle-right" />
          </div>
        </div>
      </div>
    });

    return <div className={css.shiftMenuWrapper}>
      <div className={css.shiftMenu}>
        {menu}
      </div>
      {/* <div className={css.moreArrowWrapper}>
          <FontAwesomeIcon icon="chevron-circle-down" className={css.moreArrow}/>
        </div> */}
    </div>;
  }
}

export default ShiftMenu;