//@flow

class Logger {
  _enableLogToConsole = true;
  _enableLogToFile = false;

  _dataTypes = {
    loginfo: true,
    sql: false,
    access: true,
    error: true,
    objectResolves: false,
    todo: true,
    debug: false,
  };

  _logToConsole(data: *): void {
    console.log(data);
  }

  _logToFile(): void {
    //log to file
    // Date() + data to string
  }

  log(data: *, dataType: string) {
    if (this._dataTypes[dataType] && this._enableLogToConsole) this._logToConsole(data);
    if (this._dataTypes[dataType] && this._enableLogToFile) this._logToFile(data);
  }

  setLogToConsole(s: boolean) {
    this._enableLogToConsole = s;
  }
  setLogToFile(s: boolean) {
    this._enableLogToFile = s;
  }

  setLogSql(s: boolean): void {
    this.log("SQL / Database logging enabled", "loginfo");
    this._dataTypes.sql = s;
  }
  setLogDebug(s: boolean): void {
    this.log("Debug logging enabled", "loginfo");
    this._dataTypes.debug = s;
  }
}
var logger = new Logger();
export default logger;
