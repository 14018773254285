//@flow
import * as React from "react";
import App from "./app";
import { Container } from "flux/utils";
import store from "./../data/store";
import fluxconvert from "./fluxconvert";
// import { findGetParameter } from "../lib/Helpers";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ApolloClient, InMemoryCache, ApolloProvider ,createHttpLink} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import {
  faChevronCircleRight,
  faChevronCircleLeft,
  faChevronCircleDown,
  faArrowRight,
  faArrowDown,
  faClock,
  faCar,
  faEllipsisV,
  faMapMarkerAlt,
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
library.add(faSyncAlt, faExclamationTriangle, faChevronCircleRight, faMapMarkerAlt, faChevronCircleDown, faClock, faCar, faChevronCircleLeft, faArrowRight, faEllipsisV, faArrowDown);

// console.log(at, ats);
// const at = findGetParameter("at") || "";
// const ats = findGetParameter("ats") || "";
const at = "MTExMDAwMDAwMDAwMDAwMg";
const ats = "KRa6kafjBNGiheYz";
// const protocol = process.env.USE_SSL === "yes" ? "https://" : "http://";
// const host = process.env.GRAPHQL_HOST ? process.env.GRAPHQL_HOST : "";
// const port = process.env.GRAPHQL_PORT ? process.env.GRAPHQL_PORT : 4000;
// const rooturl = process.env.DEV_MODE === "true" ? protocol + host + ":" + port  : protocol + host ;
const rooturl = "https://gql-v2.api.indemand.digital"
// const rooturl = "http://localhost:80"

const url = rooturl + `?at=${at}&ats=${ats}`;

const httpLink = createHttpLink({
  uri: url,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIyODE0NzQ5ODAwMDkwMDAzIiwiaXNBZG1pbiI6dHJ1ZX0.3aDoUz7gDxtZ3MitPWcTS7oKmKMt_yOzSA-pZZspvtg",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

class AppContainer extends React.Component<*, { store: Object }> {
  static getStores() {
    return [store];
  }

  static calculateState() {
    return {
      store: store.getState(),
    };
  }

  render() {
    if (!this.state) return null;
    return (
      <ApolloProvider client={client}>
        <App store={this.state.store} />
      </ApolloProvider>
    );
  }
}
export default Container.create(fluxconvert(AppContainer));
