//@flow
import {
  // getPrettyDate,
  getPrettyDurationFromSecs
} from '../lib/Helpers';
import * as React from 'react';

import css from './job-list.module.scss';
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  Actions
} from '../data/actions';

type JobListProps = {
  jobs: Array<Object>
}
type JobListState = {}

class JobList extends React.Component<JobListProps, JobListState> {

  handleSetPage() {
    Actions.setPage(2)
  }

  handleTouchJob(job: Object) {
    Actions.setJob(job);
  }

  render() {

    let jobs = [...this.props.jobs];

    jobs.sort(function (a, b) {
      const am = moment(a.node.pickups.edges[0].node.at);
      const bm = moment(b.node.pickups.edges[0].node.at);
      if (am > bm) return 1;
      if (am < bm) return -1;
      return 0;
    })

    const now = moment();

    const menu = jobs.map((edge, i) => {
      if (!edge.node.pickups) return null;

      const at = moment(edge.node.pickups.edges[0].node.at);
      const hasPast = now > at;

      const from = edge.node.pickups.edges[0].node.from;
      const to = edge.node.pickups.edges[0].node.to;
      const time = edge.node.pickups.edges[0].node.journey && edge.node.pickups.edges[0].node.journey.time;
      const numberOfPassengers = edge.node.pickups.edges.reduce((acc, edge) => {
        return acc + edge.node.numberOfPassengers
      }, 0)

      const arriveTime = moment(at);
      arriveTime.add(edge.node.pickups.edges[0].node.journey.time, 's')

      return <div className={css.jobMenuItem} key={i}
        onMouseUp={() => this.handleSetPage()}
        onMouseDown={() => { this.handleTouchJob(edge.node) }}
        onTouchStart={() => { this.handleTouchJob(edge.node) }}
      >
        <div className={css.jobMenuLabel}>
          <div className={[css.jobMenuItemStart, hasPast ? css.hasPast : null].join(" ")}>
            {at.format("HH:mm")}&nbsp;
              <span className={css.jobMenuItemCount}>{numberOfPassengers} {numberOfPassengers !== 1 ? "people" : "person"} / ~{getPrettyDurationFromSecs(time)}</span>
          </div>
          <div className={css.jobMenuItemName}>{from.name}</div>
          <div className={css.jobMenuItemNameSmall}>
            <FontAwesomeIcon icon="arrow-right" /> {to.name} <span className={css.arriveTime}> (arr. ~{arriveTime.format("HH:mm")})</span></div>

        </div>
        <div className={css.jobMenuIcon}>
          <FontAwesomeIcon icon="chevron-circle-right" />
        </div>
      </div>
    })
    return <div className={css.jobMenu}>
      {menu}
    </div>;
  }
}

export default JobList;