//@flow

// import invariant from 'fbjs/lib/invariant';
import moment from "moment";

// export class Helpers {
export function getPrettyDate(date: mixed): string {
  const m = new moment.utc(date);
  if (!m.isValid()) return "";
  return m.format("DD/MM/YY");
}
export function getPrettyTime(date: mixed): string {
  const m = new moment.utc(date);
  if (!m.isValid()) return "";
  return m.format("HH:mm");
}
export function getPrettyDateTime(date: mixed): string {
  // console.log(date);;
  const m = new moment.utc(date);
  if (!m.isValid()) return "";

  return m.format("DD/MM/YY @ HH:mm");
}

export function getPrettyDateDobStyle(date: mixed): string {
  const m = new moment.utc(date);
  if (!m.isValid()) return "";

  return m.format("DD/MM/YY");
}

export function getPrettyDuration(duration: Object): string {
  let mins = duration.minutes();
  let hrs = duration.hours();

  let h = hrs > 0 ? hrs + "h " : "";
  let m = mins > 0 ? mins + "mins" : "";

  return h + m;
}
export function getPrettyDurationFromMins(minutesInput: number): string {
  const duration = moment.duration(minutesInput, "minutes");
  let mins = duration.minutes();
  let hrs = duration.hours();

  let h = hrs > 0 ? hrs + "h " : "";
  let m = mins > 0 ? mins + "m" : "";

  return h + m;
}
export function getPrettyDurationFromSecs(secondsInput: number): string {
  const duration = moment.duration(secondsInput, "seconds");
  let mins = duration.minutes();
  let hrs = duration.hours();

  let h = hrs > 0 ? hrs + "h " : "";
  let m = mins > 0 ? mins + "min" : "";

  return h + m;
}

export function getPrettyDistanceFromMetres(i: number): string {
  const km = Math.round(i / 100) / 10;

  return km + "km";
}

export function getCurrencySymbol(currencyCode: string): string {
  if (currencyCode === "GBP") return "£";
  if (currencyCode === "USD") return "$";
  if (currencyCode === "EUR") return "€";
  return "#";
}

export function copyToClipboard(text: string) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (document.queryCommandSupported && window.document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    window.document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      window.document.body.removeChild(textarea);
    }
  }
}
export function findGetParameter(parameterName: string) {
  let result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

let replacerURL = "";

function replacer(match, p1) {
  // console.log(match);
  // console.log(p1);
  const url = `<a href=${replacerURL}>${p1}</a>`;
  return url;
}
export function replaceLinkTags(text: string, url: string): string {
  replacerURL = url;
  if (typeof text === "string") return text.replace(/##link:(.*?)##/gi, replacer);
  return text;
}

export function getRaw(html: string): Object {
  return {
    __html: html,
  };
}

export function urlIsImage(url: string): boolean {
  if (url.endsWith(".jpg")) return true;
  if (url.endsWith(".jpeg")) return true;
  if (url.endsWith(".png")) return true;
  if (url.endsWith(".gif")) return true;
  return false;
}
export function urlIsVideo(url: string): boolean {
  if (url.endsWith(".mp4")) return true;
  if (url.endsWith(".mov")) return true;
  return false;
}
export function formatPostcode(postcode: string): string {
  if (!postcode) return "";
  const p = postcode.replace(/ /g, "").toUpperCase().slice(0, 7);
  const p1 = p.substr(0, p.length - 3);
  const p2 = p.substr(-3);
  return p1 + " " + p2;
}
export function formatPhonenumber(phonenumber: string): string {
  if (!phonenumber) return "";
  const p = phonenumber.replace(/ /g, "");
  const p1 = p.substr(0, 5);
  const p2 = p.substr(5, 3);
  const p3 = p.substr(8);

  return p1 + " " + p2 + " " + p3;
}
export function getRandomColour() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export function getColourWithIndex(i: number, offset?: number = 0): string {
  const colours = [
    "#730000",
    "#f28100",
    "#e03030",
    "#149900",
    "#1a6661",
    "#80a2ff",
    "#30264d",
    "#f200e2",
    "#33000e",
    "#d9b1a3",
    "#b2982d",
    "#1b3300",
    "#79f2da",
    "#bfe1ff",
    "#0000ff",
    "#632699",
    "#a6537f",
    "#e53950",
  ];
  // if (!offset) offset = 0;
  return colours[(i + offset) % colours.length];
}

export function shrinkTextToFit(s: string, fit: number): string {
  let t = "";

  //common abbreviations
  s = s.replace(/ and /gi, " & ");
  if (s.length <= fit) return s;

  s = s.replace(/ ?the /gi, " ");
  if (s.length <= fit) return s;

  s = s.replace(/station/gi, "Stn");
  if (s.length <= fit) return s;

  s = s.replace(/hotel/gi, "Htl");
  if (s.length <= fit) return s;

  s = s.replace(/street/gi, "Str");
  if (s.length <= fit) return s;

  s = s.replace(/road/gi, "Rd");
  if (s.length <= fit) return s;

  s = s.replace(/airport/gi, "Airpt");
  if (s.length <= fit) return s;

  s = s.replace(/ park /gi, " Prk ");
  if (s.length <= fit) return s;

  //strip symbols
  while (s.length > fit) {
    t = s.replace(/[!'",.](?!.*[!'",.])/, "");
    if (t === s) break;
    s = t;
  }

  //strip vowels
  while (s.length > fit) {
    t = s.replace(/[aeiou](?!.*[aeiou])/, "");
    if (t === s) break;
    s = t;
  }

  //strip end of words
  while (s.length > fit) {
    let words = s.split(" ");
    for (let word = 0; word < words.length; word++) {
      if (word === 0) {
        if (words[word].length > 4) words[word] = words[word].substring(0, words[word].length - 1);
      } else {
        if (words[word].length > 3) words[word] = words[word].substring(0, words[word].length - 1);
      }
    }
    t = words.join(" ");
    // t = s.replace(/[aeiou](?!.*[aeiou])/, '');
    if (t === s) break;
    s = t;
  }

  //strip from end
  while (s.length > fit) {
    t = s.substring(0, s.length - 2);
    if (t === s) break;
    s = t;
  }

  return s;
}

export function addUrlQuery(key: string, value: string): void {
  if (window.history.replaceState) {
    let path = window.location.origin + window.location.pathname;
    if (!path.endsWith("?")) path += "?";
    const newurl = path + key + "=" + value + "&";
    window.history.replaceState(
      {
        path: newurl,
      },
      "",
      newurl
    );
  }
}
