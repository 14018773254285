// @flow
import React from 'react';
// import ReactDOM from 'react-dom';
import {
  FontAwesomeIcon as FontAwesome
} from '@fortawesome/react-fontawesome';
import {
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons'

const Loading = () => (
  <div style={{width: '100%', height: '100%',textAlign: 'center',padding:'50px','color':'white'}}>
  <FontAwesome
      icon={faSyncAlt}
      size='5x'
      spin={true}
      style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
    />
    {/* <FontAwesome icon="sync" /> */}
  </div>


)

export default Loading;