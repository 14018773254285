//@flow
import * as React from "react";
import Job from "./job";
import ShiftMenu from "./shift-menu";
import Shift from "./shift";
import css from "./driver-hub.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";
import { Actions } from "../data/actions";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const DriverHub = (props: { data: Object, store: Object }) => {
  const { data, store } = props;
  const handleChangeIndex = (i: number) => {
    Actions.setPage(i);
  };

  if (!data) return null;
  const brand = data.public.brand;

  if (!brand) return null;

  const pages = [<ShiftMenu key={0} shifts={brand.gtShifts.edges} />, <Shift key={1} shift={store.get("shift")} />, <Job key={2} job={store.get("job")} />];

  const slideRenderer = ({ index }) => {
    return pages[index];
  };

  const alignLeft = store.get("page") > 0 ? css.headerTextLeft : null;
  const showBack = store.get("page") > 0 ? css.headerBackButtonOn : null;

  return (
    <div className={css.hubWrapper}>
      <div className={css.header} onClick={() => Actions.pageBack()}>
        <div className={[css.headerText, alignLeft].join(" ")}>
          <FontAwesomeIcon icon="car" />
          <div>{brand.name}</div>
          <div>Driver hub</div>
        </div>
        <div className={[css.headerBackButton, showBack].join(" ")}>
          <FontAwesomeIcon icon="chevron-circle-left" />
        </div>
      </div>

      <div className={css.shiftMenu}>
        <VirtualizeSwipeableViews index={store.get("page")} onChangeIndex={handleChangeIndex} slideRenderer={slideRenderer} slideCount={3}></VirtualizeSwipeableViews>
      </div>
    </div>
  );
};

export default DriverHub;
