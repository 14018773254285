// @flow
const fc = (containerClass: Object) => {
  const tmp = containerClass;
  //   $FlowExpectedError
  containerClass = function (...args:[*]) {
    return new tmp(...args);
  };
  containerClass.prototype = tmp.prototype;
  containerClass.getStores = tmp.getStores;
  containerClass.calculateState = tmp.calculateState;
  return containerClass;
};
export default fc;
