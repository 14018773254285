// @flow
// import NewDataAvailable from '../lib/new-data-available/new-data-available';
import * as React from 'react';
import DriverHub from '../components/driverhub-container';

// import ErrorBoundary from 'lib/error-boundary';
import BG from './../img/bg1.jpg';
import backgroundcss from '../components/background.module.scss';


type Props = {
  store: Object
}

class App extends React.Component<Props> {
  render() {
    return <div >
      <div className={backgroundcss.background} style={{ 'backgroundImage': `url(${BG})` }}></div>
      <DriverHub {...this.props} />
      {/* <NewDataAvailable show={this.props.store.get("updateAvailable")} height={null} /> */}
    </div>
  }
}
export default App