//@flow
import Dispatcher from './../app/Dispatcher';
import Store from './store';

const ActionTypes = {
  SET_PAGE: "SET_PAGE",
  SET_SHIFT: "SET_SHIFT",
  SET_JOB: "SET_JOB",
  SET_UPDATE_AVAILABLE: "SET_UPDATE_AVAILABLE",
  SET_LOADING: "SET_LOADING"
};


const Actions = {
  setPage(data: number) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_PAGE,
      data: data
    });
    setTimeout(() =>
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      }), 300);
  },
  setShift(data: Object) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_SHIFT,
      data: data
    })
  },
  setJob(data: Object) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_JOB,
      data: data
    })
  },
  pageBack() {
    const nextPage = Store.getState().get("page") > 0 ? Store.getState().get("page") - 1 : 0;
    Actions.setPage(nextPage)
  },
  setUpdateAvailable(data: bool) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_UPDATE_AVAILABLE,
      data: data
    })
  },
  reloadData() {
    Dispatcher.dispatch({
      type: ActionTypes.SET_LOADING,
      data: true
    });
    setTimeout(() => {
      Dispatcher.dispatch({
        type: ActionTypes.SET_LOADING,
        data: false
      });
    }, 300);
  }
};
export {
  Actions,
  ActionTypes
};