//@flow
import {
  OrderedMap
} from 'immutable';

import {
  ReduceStore
} from 'flux/utils';

import Dispatcher from './../app/Dispatcher';
import {
  ActionTypes
} from './actions';

class Store extends ReduceStore < Object > {
  constructor() {
    super(Dispatcher);
  }

  getInitialState(): Object {
    return OrderedMap({
      page: 0
    });
  }

  reduce(state: Object, action: Object): Object {
    switch (action.type) {
      case ActionTypes.SET_SHIFT:
        return state.set("shift", action.data);
      case ActionTypes.SET_JOB:
        return state.set("job", action.data);
      case ActionTypes.SET_PAGE:
        return state.set("page", action.data);
      case ActionTypes.SET_LOADING:
        return state.set("loading", action.data);
      case ActionTypes.SET_UPDATE_AVAILABLE:
        return state.set("updateAvailable", action.data);
      default:
        return state;
    }
  }
}

export default new Store();